const initialState = {
    selected: [],
};

export default (state = initialState, action) => {

    switch (action?.type) {

        case 'SELECT_QUESTS':
            return { ...state, selected: [...state.selected, action.payload] };
        case 'SELECT_ALL_QUESTS':
            return { ...state, selected: action.payload };

        case 'RESET_QUESTS':
            return { ...state, selected: [] };
        case 'UPDATE_QUESTS':
            let alreadyExists = state.selected.filter((e) => e.id != action.payload.id)
            return { ...state, selected: alreadyExists }
        // if (alreadyExists.length > 0) {
        //     return { ...state };
        // } else {
        //     return { ...state, selected: [...state.selected, action.payload] };
        // }

        default:
            return state;
    }
};