import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import ImageView from './ImageView';
import VideoView from './VideoView';
import { fileEmpty } from 'react-icons-kit/icomoon/fileEmpty'
import Icon from 'react-icons-kit';

const defaultUserImg = require('../assets/user.png');

const ChatMessage = (props) => {

    const user = useSelector(state => state.user);
    const { msgdata, character, varient } = props;
    const [userData, setUserData] = useState({});
    const [showImageView, setShowImageView] = useState(false);
    const [imageToShow, setImageToShow] = useState(null);
    const [showVideoView, setShowVideoView] = useState(false);
    const [videoToShow, setVideoToShow] = useState(null);

    const showImage = (e) => {
        setImageToShow(e)
        document.body.style.overflow = "hidden"
        setShowImageView(true)
    }
    const showVideo = (e) => {
        setVideoToShow(e)
        document.body.style.overflow = "hidden"
        setShowVideoView(true)
    }

    const onClose = () => {
        document.body.style.overflow = 'initial'
        setShowImageView(false)
    }
    const onCloseVideo = () => {
        document.body.style.overflow = 'initial'
        setShowVideoView(false)
    }

    function getExtension(filename) {
        var parts = filename.split('.');
        return parts[parts.length - 1];
    }
    function isImage(filename) {
        var ext = getExtension(filename);
        switch (ext.toLowerCase()) {
            case 'jpg':
            case 'gif':
            case 'bmp':
            case 'png':
            case 'jpeg':
                //etc
                return true;
        }
        return false;
    }

    function isVideo(filename) {
        var ext = getExtension(filename);
        switch (ext.toLowerCase()) {
            case 'm4v':
            case 'avi':
            case 'mpg':
            case 'mp4':
                // etc
                return true;
        }
        return false;
    }

    useEffect(() => {
        setUserData(user.data);
    }, [user])
    useEffect(() => {
        console.log(msgdata.character?.image);
    }, [msgdata.character?.image])

    return (
        <>
            <ImageView showImageView={showImageView} image={imageToShow} onClose={onClose} />
            <VideoView showVideoView={showVideoView} video={videoToShow} onClose={onCloseVideo} />
            <div className='msg_container' style={{ alignSelf: msgdata.by == "C" ? "flex-start" : 'flex-end', alignItems: varient == 'homepage' ? 'last baseline' : 'initial' }} >
                {msgdata.by == "C" ?
                    varient == 'homepage' ?
                        <div style={{ width: '70px', minWidth: '70px', height: '70px', display: 'flex', justifyContent: 'center', alignItems: 'center', overflow: 'hidden', border: '2px solid black', borderRadius: '50%', padding: "10px" }} >
                            <img src={msgdata.character?.image || defaultUserImg} style={{ maxWidth: '100%', height: '100%', objectFit: 'contain' }} />
                        </div>
                        :
                        <img src={msgdata.character?.image || defaultUserImg} style={{ height: '35px', width: "35px", marginTop: 5, objectFit: 'contain' }} alt='profile picture' />
                    : ''
                }
                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: 'flex-end'
                }}
                >
                    {msgdata.files &&
                        <div style={{ margin: "0px 10px", backgroundColor: msgdata.by == "C" ? 'rgba(232, 236, 239, 0.4)' : 'rgba(146, 207, 213, 0.3)', padding: '10px 15px', overflowWrap: 'break-word', textAlign: 'left', borderRadius: '10px', display: 'flex', marginTop: 2, width: 'fit-content', position: 'relative' }}>
                            {
                                typeof msgdata.files != 'string' ?
                                    Array.from(msgdata.files).map((e, i) => {
                                        if (msgdata.by == 'C') {
                                            return (
                                                <div key={i} style={{ position: 'relative', width: '130px', height: "130px", background: '#fff', padding: 10, fontSize: 14, overflow: 'hidden', margin: 4, borderRadius: 10 }} >
                                                    <div style={{ height: '60px' }}  >
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" fill="currentColor" className="bi bi-file-earmark" viewBox="0 0 16 16">
                                                            <path d="M14 4.5V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h5.5zm-3 0A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V4.5z" />
                                                        </svg>
                                                    </div>
                                                    {e.name}
                                                </div>
                                            )
                                        } else {
                                            if (isImage(e.name)) {
                                                return (
                                                    <div style={{ cursor: 'pointer', overflowWrap: 'break-word', textAlign: 'left', borderRadius: '10px', width: 'fit-content' }}>
                                                        <img key={i} onClick={() => showImage(URL.createObjectURL(e))} src={URL.createObjectURL(e)} className='message_img' />
                                                    </div>
                                                )
                                            } else if (isVideo(e.name)) {
                                                return (
                                                    <>
                                                        <div style={{ cursor: 'pointer', overflowWrap: 'break-word', textAlign: 'left', borderRadius: '10px', width: 'fit-content' }}>
                                                            <video width={130}>
                                                                <source alt="preview image" style={{ width: '130px', height: '130px', objectFit: 'cover' }} src={URL.createObjectURL(e)} />
                                                            </video>
                                                        </div>
                                                        <div onClick={() => showVideo(URL.createObjectURL(e))} style={{ cursor: 'pointer', position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 0 }}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="white" className="bi bi-play-circle" viewBox="0 0 16 16">
                                                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                                                                <path d="M6.271 5.055a.5.5 0 0 1 .52.038l3.5 2.5a.5.5 0 0 1 0 .814l-3.5 2.5A.5.5 0 0 1 6 10.5v-5a.5.5 0 0 1 .271-.445" />
                                                            </svg>
                                                        </div>
                                                    </>
                                                )
                                            } else {
                                                return (
                                                    <div key={i} style={{ position: 'relative', width: '130px', height: "130px", background: '#fff', padding: 10, fontSize: 14, overflow: 'hidden', margin: 4, borderRadius: 10 }} >
                                                        <div style={{ height: '60px' }}  >
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" fill="currentColor" className="bi bi-file-earmark" viewBox="0 0 16 16">
                                                                <path d="M14 4.5V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h5.5zm-3 0A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V4.5z" />
                                                            </svg>
                                                        </div>
                                                        {e.name}
                                                    </div>
                                                )
                                            }
                                        }
                                    })
                                    : <div style={{ position: 'relative' }}>
                                        {msgdata.files.includes("data:image") ?
                                            <div style={{ cursor: 'pointer', overflowWrap: 'break-word', textAlign: 'left', borderRadius: '10px', width: 'fit-content' }}>
                                                <img onClick={() => showImage(msgdata.files)} src={msgdata.files} className='message_img' style={{ margin: 4, objectFit: 'cover', background: '#fff', padding: 10, borderRadius: 10 }} />
                                            </div> :
                                            isImage(msgdata.files) ?
                                                <div style={{ cursor: 'pointer', overflowWrap: 'break-word', textAlign: 'left', borderRadius: '10px', width: 'fit-content' }}>
                                                    <img onClick={() => showImage(msgdata.files)} src={msgdata.files} className='message_img' />
                                                </div>
                                                :
                                                isVideo(msgdata.files) ?
                                                    <div onClick={() => showVideo(msgdata.files)} style={{ cursor: 'pointer', overflowWrap: 'break-word', textAlign: 'left', borderRadius: '10px', width: 'fit-content' }}>
                                                        <video width={130} id="sent_video" >
                                                            <source alt="preview image" style={{ width: '130px', height: '130px', objectFit: 'cover' }} src={msgdata.files} />
                                                        </video>
                                                        <div style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 0 }}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="white" className="bi bi-play-circle" viewBox="0 0 16 16">
                                                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                                                                <path d="M6.271 5.055a.5.5 0 0 1 .52.038l3.5 2.5a.5.5 0 0 1 0 .814l-3.5 2.5A.5.5 0 0 1 6 10.5v-5a.5.5 0 0 1 .271-.445" />
                                                            </svg>
                                                        </div>
                                                    </div>
                                                    : <div style={{ cursor: 'pointer', position: 'relative', width: '130px', height: "130px", background: '#fff', padding: 10, fontSize: 14, overflow: 'hidden', margin: 4, borderRadius: 10 }} >
                                                        <div style={{ height: '60px' }}  >
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" fill="currentColor" className="bi bi-file-earmark" viewBox="0 0 16 16">
                                                                <path d="M14 4.5V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h5.5zm-3 0A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V4.5z" />
                                                            </svg>
                                                        </div>
                                                        {msgdata.files}
                                                    </div>
                                        }
                                    </div>

                            }
                        </div>}
                    {(msgdata.message) ?
                        varient == 'homepage' ?
                            msgdata.by == "C" ?
                                <div className='d-flex align-self-start align-items-end mb-3'>
                                    {/* <div style={{ width: '60px', height: '60px', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: "10px", border: '2px solid black', borderRadius: '50%' }} ><img src={defaultCharactor?.image} style={{ maxWidth: '90%' }} /></div> */}
                                    <div className='message_container_landing_left' style={{ marginLeft: "50px", padding: '12px', backgroundColor: 'rgba(37, 33, 52, 1)', color: 'white', borderRadius: '10px' }} >
                                        <div style={{ fontSize: '20px', overflowWrap: 'anywhere' }}>{msgdata.message}</div>
                                    </div>
                                </div>
                                :
                                <div className='d-flex align-self-end align-items-end my-3 '>
                                    <div className='message_container_landing_right' style={{ marginRight: "40px", padding: '12px', backgroundColor: 'rgba(159, 205, 212, 1)', color: 'white', borderRadius: '10px' }} >
                                        <div style={{ fontSize: '20px', overflowWrap: 'anywhere' }}>{msgdata.message}</div>
                                    </div>
                                    {/* <div style={{ width: '60px', height: '60px', display: 'flex', justifyContent: 'center', alignItems: 'center', overflow: 'hidden', border: '2px solid black', borderRadius: '50%' }} >
                                        <img src={user?.data?.photo ? user.data.photo : defaultimg} style={{ maxWidth: '100%', objectFit: 'cover' }} />
                                    </div> */}
                                </div>
                            :
                            <div style={{ margin: "2px 10px", backgroundColor: msgdata.by == "C" ? 'rgba(232, 236, 239, 0.4)' : 'rgba(146, 207, 213, 0.3)', padding: '10px 15px', overflowWrap: 'break-word', textAlign: 'left', borderRadius: '10px' }}>{msgdata.message}</div>
                        : ""
                    }
                </div>
                {/* {msgdata.by != "C" && <img src={userData?.photo || defaultUserImg} height={35} style={{ marginTop: 5, borderRadius: "50%" }} alt='Character picture' />} */}
                {msgdata.by != "C" ?
                    varient == 'homepage' ?
                        <div style={{ width: '70px', height: '70px', minWidth: "70px", display: 'flex', justifyContent: 'center', alignItems: 'center', overflow: 'hidden', border: '2px solid black', borderRadius: '50%' }} >
                            <img referrerPolicy="no-referrer" src={userData?.photo || defaultUserImg} style={{ maxWidth: '100%', height: '100%', objectFit: 'cover', borderRadius: "50%", }} alt='model picture' />
                        </div>
                        :
                        <img referrerPolicy="no-referrer" src={userData?.photo || defaultUserImg} style={{ height: '35px', width: "35px", marginTop: 5, borderRadius: "50%", objectFit: 'cover' }} alt='model picture' />
                    : ''}
            </div >
        </>
    )
}

export default ChatMessage