import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

const Footer = () => {

    const isLogged = useSelector((state) => state.user.isLogged);
    const navigate = useNavigate()

    return (
        <footer style={{ backgroundColor: '#37BFCB', height: '164px'  }} className='p-2'>
            <div className='d-flex justify-content-between align-items-center p-2 p-md-4 '>
                <div onClick={() => navigate('/')} className='footer_logo'>
                    <img src={require('../assets/logo.png')} style={{ maxWidth: '150px' }} />
                </div>
                <div className='d-none d-md-flex'>
                    <Link className='text-dark fw-bold text-decoration-none mx-4' style={{ fontSize: 13 }} to={isLogged ? '/chat' : '/login'} state={{ from: "chat" }} >CHAT</Link>
                    <Link className='text-dark fw-bold text-decoration-none mx-4' style={{ fontSize: 13 }} to={isLogged ? '/insights' : '/login'} state={{ from: "insights" }}>INSIGHT</Link>
                    <Link className='text-dark fw-bold text-decoration-none mx-4' style={{ fontSize: 13 }} to={'/models'}>MODELS</Link>
                    <Link className='text-dark fw-bold text-decoration-none mx-4' style={{ fontSize: 13 }} to={isLogged ? '/settings' : '/login'} state={{ from: "settings" }}>SETTINGS</Link>
                </div>
            </div>
            <div className='d-flex d-md-none flex-1 justify-content-between my-2'>
                <Link className='text-dark text-decoration-none mx-1' style={{ fontSize: '13px' }} to={isLogged ? '/chat' : '/login'} state={{ from: "chat" }}>CHAT</Link>
                <Link className='text-dark text-decoration-none mx-1' style={{ fontSize: '13px' }} to={isLogged ? '/insights' : '/login'} state={{ from: "insights" }}>INSIGHT</Link>
                <Link className='text-dark text-decoration-none mx-1' style={{ fontSize: '13px' }} to={'/models'}>MODELS</Link>
                <Link className='text-dark text-decoration-none mx-1' style={{ fontSize: '13px' }} to={isLogged ? '/settings' : '/login'} state={{ from: "settings" }}>SETTINGS</Link>
            </div>
            <div className='d-flex justify-content-between align-items-center mx-2 mx-md-4 my-2' style={{ fontSize: '12px' }}>
                <div>© 2021-2024 Nestheads Inc.</div>
                <div className='d-flex justify-content-between align-items-center'>
                    <a href='https://www.facebook.com' target='_blank' className='homepage_footer'><img src={require('../assets/fb.png')} /></a>
                    <a href='https://discord.com/servers' target='_blank' className='homepage_footer'><img src={require('../assets/discord.png')} /></a>
                    <a href='https://web.telegram.org/a/' target='_blank' className='homepage_footer'><img src={require('../assets/telegram.png')} /></a>
                </div>
            </div >
        </footer >
    )
}

export default Footer