import React, { useEffect, useState } from 'react';
import ModelListing from '../components/ModelListing';
import { useNavigate } from 'react-router-dom';
import CharacterDetails from './CharacterDetails';
import { useLocation } from 'react-router-dom';
import Api from '../services/Api';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { toast } from 'react-toastify';
import Footer from '../components/footer';
import { setQuestsdata, resetQuests, removeQuestsdata } from '../actions/questActions';
import { useDispatch } from 'react-redux';
import Buttons from '../components/Buttons';

// let _characters = [
//   {
//     img: require('../assets/categories/beliefs.png'),
//     name: 'Beliefs'
//   },
//   {
//     img: require('../assets/categories/biases.png'),
//     name: 'Biases'
//   },
//   {
//     img: require('../assets/categories/emotions.png'),
//     name: 'Emotions'
//   },
//   {
//     img: require('../assets/categories/gratitude.png'),
//     name: 'Gratitude'
//   },
//   {
//     img: require('../assets/categories/habits.png'),
//     name: 'Habits'
//   },
//   {
//     img: require('../assets/categories/neurotransmitters.png'),
//     name: 'Neurotransmitters'
//   },
//   {
//     img: require('../assets/categories/triggers.png'),
//     name: 'Triggers'
//   },
// ]

const Models = () => {

  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const [_helmet, setHelmet] = useState({ title: "models", des: 'models description' })
  const [loaded, setLoaded] = useState(true)
  const [selectedFilter, setSelectedFilter] = useState('new');
  const [characterCategory, setCharacterCategory] = useState(false);
  const [categories, setCategories] = useState([])
  const dispatch = useDispatch()

  const isLogged = useSelector((state) => state.user.isLogged);
  const selected = useSelector((state) => state.quests.selected);

  const navigate = useNavigate();
  const location = useLocation();

  const details = (e, ele) => {
    ele.preventDefault()
    if (isLogged) {
      navigate(`/models/${(e?.seo || 'model')}`)
    } else {
      navigate((e?.seo || 'model'))
    }
  }

  const updateLike = (e) => {

    if (selected.find((item) => item.id == e.id)) {
      selected.filter((item) => item.id != e.id)
      dispatch(removeQuestsdata(e))
    } else {
      dispatch(setQuestsdata(e))
    }

    // Api.post('characters/like',
    //   {
    //     type: e.liked ? 0 : 1,
    //     character_id: JSON.stringify(e.id)
    //   })
    //   .then((res) => {
    //     if (res.success) {
    //       let _data = []
    //       data.map((ele) => {
    //         if (ele.id == e.id) {
    //           ele['liked'] = e.liked ? false : true
    //           _data.push(ele)
    //         } else {
    //           _data.push(ele)
    //         }
    //       })
    //       setData(_data)
    //     } else {

    //     }
    //   })
    //   .catch(() => {

    //   })
  }

  useEffect(() => {
    localStorage.setItem('charactersdata', JSON.stringify(selected))
  }, [selected])

  useEffect(() => {
    setLoading(true)
    setHelmet({ title: "models", des: 'models description' });
    let url = isLogged ? 'characters/list' : 'characters/public/list'
    let characters = Api.post(url);
    // let categories = Api.get('categories');

    Promise.all([characters, categories])
      // Promise.all([characters, categories])
      .then((res) => {
        if (res[0].success) {
          setData(res[0].data)
        } else {
          setData([])
        }

        if (res[1].success) {
          // setCategories(res[1].data.character);
        } else {
          // setCategories(_characters);

        }
      })
      .catch((err) => {
        setData([])
      })
      .finally(() => {
        setLoading(false)
      })

    // return () => dispatch(resetQuests())
  }, [location])

  useEffect(() => {
    setLoaded(false)
    setTimeout(() => {
      setLoaded(true)
    }, 100)
  }, [_helmet])

  const onFilterChange = (e) => {
    setSelectedFilter(e.target.value)
    setLoading(true)

    let url = characterCategory ? `chat-category/detail/${characterCategory.id}${selectedFilter && ("?filter=" + e.target.value)}` : `characters${isLogged ? "" : '/public'}/list?filter=${e.target.value}`
    Api.post(url)
      .then((res) => {
        if (res.success) {
          if (characterCategory) {
            setData(res.data[0].character)
          } else {
            setData(res.data)
          }
        } else {
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }


  useEffect(() => {
    setLoading(true);
    // let _characterItems = localStorage.getItem("charactersItems")
    // if (_characterItems != null) {
    //     setCharacterItems(JSON.parse(_characterItems))
    // }

    Api.post('chat-category/list')
      .then((res) => {
        if (res.success) {
          setCategories(res.data)
          // scrollToTop()
        } else {
        }
      })
      .catch((err) => {
      })
      .finally(() => {
        setLoading(false);
      })
  }, [])

  const onCategorySelect = (item) => {
    if (characterCategory.id == item.id) {
      setCharacterCategory(false);
      let url = isLogged ? 'characters/list' : 'characters/public/list'
      Api.post(url)
        .then((res) => {
          if (res.success) {
            setData(res?.data || [])
          } else {
            setData([])
          }
        })
      // let categories = Api.get('categories');

    } else {
      setCharacterCategory(item)
      Api.post(`chat-category/detail/${item.id}${selectedFilter && ("?filter=" + selectedFilter)} `)
        .then((res) => {
          if (res.success) {
            setData(res?.data[0].character || [])
          }
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }

  return (
    <div className='main_outer_container'>
      <div className='page_container'>
        <div className='d-flex no-wrap overflow-scroll' >
          {loading ?
            <div className='image_container' style={{ height: '166px', display: 'flex' }}>
              <div className='models_loading_container' >
              </div>
              <div className='models_loading_container' >
              </div>
              <div className='models_loading_container' >
              </div>
              <div className='models_loading_container' >
              </div>
            </div>
            : categories.map((item, i) => {
              return (
                <div key={i} onClick={() => onCategorySelect(item)} style={{ padding: '10px 0px' }} className={`character_category_container ${characterCategory.id == item.id && 'active'}`}>
                  <div className='image_container' >
                    <img src={item.image} width={"100%"} height={'100%'} style={{ objectFit: 'contain' }} />
                  </div>
                  <div className='text-center'  >{item.name}</div>
                </div>
              )
            })
          }
        </div>
        <div className='character_listing_container' >
          <div className='charactor_filter_container' >
            {/* <Buttons title={'Create Character'} loading={false} onClick={() => navigate('/create-character')} /> */}
            <div className='my-4 mx-2 text-right'>
              <select className='character_filter' value={selectedFilter} style={{ fontFamily: 'Dimbo' }} title='Filter' onChange={onFilterChange}>
                <option className='filter_options' value={'new'}>newest</option>
                <option className='filter_options' value={'name'} >name</option>
                <option className='filter_options' value={'popularity'}>popularity</option>
                {/* <option className='filter_options' style={{ display: 'none' }} selected={selectedFilter == 'default'} >default</option> */}
              </select>
            </div>
          </div>
          <ModelListing data={data} onClick={details} updateLike={updateLike} loading={loading} />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Models;