import React, { useEffect } from "react";
import { Icon } from 'react-icons-kit'
import { checkmark } from 'react-icons-kit/icomoon/checkmark'

const CheckBox = (props) => {

    const { value, onChange = () => { }, text = "" } = props;

    return (
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }} >
            <div onClick={onChange} style={{ border: '2px solid #000', cursor: 'pointer', borderRadius: 5, marginRight: 10, width: 20, height: 20, display: 'flex', justifyContent: 'center', alignItems: 'center' }} >{value && <Icon icon={checkmark} size={12} />}</div>
            {/* <div id="checkbox_text" >{text}</div> */}
        </div>
    )
}

export default CheckBox

