import React, { useState, useRef, useEffect } from "react"
import Buttons from "../components/Buttons";
import Footer from "../components/footer";
import { Modal } from "react-bootstrap";
import { Filter } from 'bad-words'
import Api from '../../src/services/Api'
import { Alert } from "react-bootstrap";
import { useSelector } from "react-redux";
import CheckBox from "../components/CheckBox";
import { useAsyncError, useNavigate } from "react-router-dom";
import InputField from "../components/InputField";
import { useDispatch } from "react-redux";
import { resetQuests } from "../actions/questActions";
import Lottie from 'lottie-react';
import animationData from '../assets/data.json';

const CreateCharacter = (props) => {

    const filter = new Filter();

    const [characterCategory, setCharacterCategory] = useState(false);
    const [categories, setCategories] = useState([]);
    const [character_type, setCharacterType] = useState('t');
    const [characterImage, setCharecterImage] = useState({ 'h': false, "t": false });
    const [formData, setFormData] = useState({ 'h': {}, "t": {} });
    const [staticFormData, setStaticFormData] = useState({});
    const [isModelOpen, setModelOpen] = useState(false);
    const [isTermsOpen, setTermsModel] = useState(false);
    const [characterItemImage, onSelectCharacterImage] = useState(false);
    const [characterItems, setCharacterItems] = useState([]);
    const [itemdes, setItemDes] = useState('');
    const [modalError, setModalError] = useState(false);
    const [imageChecking, setImageChecking] = useState(false);
    const [loadingFromAi, setLoadingFromAI] = useState(false);
    const [savingCharacter, setLoadingsaveCharacter] = useState(false);
    const [_alert, setAlert] = useState({ varient: '', msg: '' });
    const [loading, setLoading] = useState(false);
    const [termsAccepted, setTermsAcceptance] = useState(false);
    const [isPublic, setPublic] = useState(false);
    const [termsErr, setTermsError] = useState(false)
    const [isInputFieldActive, setInputFieldActive] = useState(false)
    const [nestname, setNestName] = useState('New N.E.S.T.')

    const [stateVales, setStatesVals] = useState({})

    const imageref = useRef();
    const characterItemImageref = useRef();
    const titleRef = useRef();
    const navigate = useNavigate();
    const activeSubscription = useSelector(state => state.user.subscription);
    const quests = useSelector(state => state.quests.selected);
    const dispatch = useDispatch()

    const scrollToTop = () => {
        window.scrollTo(0, 0);
    }

    const changecategorytype = (type) => {
        setCharacterType(type);
    }

    const openTermsModel = () => {
        setAlert({ msg: "", varient: "" })
        let helthylen = 0;
        let toxiclen = 0;

        characterItems.map((item) => {
            if (item.type == 'h') {
                helthylen++
            } else if (item.type == 't') {
                toxiclen++
            }
        })

        if (helthylen != toxiclen) {
            if (helthylen < toxiclen) {
                setAlert({ msg: "Please add missing healthy item !", varient: "danger" })
            } else {
                setAlert({ msg: "Please add missing toxic item !", varient: "danger" })
            }
            window.scrollTo(0, 0)
            return
        }
        if (!characterCategory?.name) {
            setAlert({ msg: "Please select a category !", varient: "danger" })
            window.scrollTo(0, 0)
            return
        }
        if (!stateVales?.undesirable) {
            setAlert({ msg: "Please enter undesirable state!", varient: "danger" })
            window.scrollTo(0, 0)
            return
        }
        if (!stateVales?.desirable) {
            setAlert({ msg: "Please enter desirable state!", varient: "danger" })
            window.scrollTo(0, 0)
            return
        }


        setTermsModel(true);
    }

    const saveCharacter = () => {
        if (!termsAccepted) {
            setTermsError(true);
            return
        }

        setLoadingsaveCharacter(true);

        let helthylen = [];
        let toxiclen = [];

        characterItems.map((item) => {
            if (item.type == 'h') {
                helthylen.push(item)
            } else if (item.type == 't') {
                toxiclen.push(item)
            }
        })

        let saveForm = new FormData();

        let charactersIds = [];

        quests.map((item) => {
            charactersIds.push(item.id + '')
        })

        let image = characterCategory.image.split('/');
        const filenameWithExtension = image.pop();

        saveForm.append("category_id", characterCategory.id)
        saveForm.append("character_id", charactersIds)
        saveForm.append("is_public", isPublic ? 1 : 0)
        saveForm.append("desired_state", stateVales.desirable)
        saveForm.append("undesired_state", stateVales.undesirable)
        saveForm.append("default_name", nestname)
        saveForm.append("image", filenameWithExtension)
        let hl = 0
        let tl = 0

        for (let i = 0; i < characterItems.length; i++) {
            if (characterItems[i].type == 'h') {
                hl++
                // saveForm.append('healthy_image_' + hl, characterItems[i].image);
                saveForm.append('healthy_item_' + hl, characterItems[i].title);
            }
        }
        for (let i = 0; i < characterItems.length; i++) {
            if (characterItems[i].type == 't') {
                tl++
                // saveForm.append('toxic_image_' + tl, characterItems[i].image);
                saveForm.append('toxic_item_' + tl, characterItems[i].title);
            }
        }

        let headers = {
            "content-type": "multipart/form-data",
            'Authorization': "Bearer " + localStorage.getItem('nestheadToken'),
            'Accept': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Max-Age': 600,
        }

        Api.post('chat/create/nest', saveForm, { headers })
            .then((res) => {
                if (res.success) {
                    dispatch(resetQuests())
                    navigate('/chat/category/quests')
                } else {
                    setAlert({ msg: res.message, varient: 'danger' });
                    scrollToTop();
                }
            })
            .catch((err) => {
                setAlert({ msg: 'Error Occured', varient: 'danger' });
                scrollToTop();
            })
            .finally(() => {
                setLoadingsaveCharacter(false)
                setTermsModel(false)
            })
    }

    const onFieldValueChange = (k, v) => { //required

        let _formData = { ...formData };
        _formData[character_type][k] = v;

        if (_formData[character_type][k].trim() == '') {
            delete _formData[character_type][k]
        }
        setFormData(_formData)
    }

    const onStaticFieldValueChange = (k, v) => { //required
        let _formData = { ...staticFormData };
        _formData[k] = v;

        if (_formData[k].trim() == '') {
            delete _formData[k]
        }
        setStaticFormData(_formData)
    }

    const addCharacter = async (e) => {
        if (itemdes.trim() == '') {
            setModalError('Please Enter  Description!')
            return
        }
        e.preventDefault();
        if (!characterCategory) {
            setAlert({ msg: 'Please select a category First!', varient: 'danger' })
            scrollToTop()
            return
        }
        if (itemdes) {
            if (!filter.isProfane(itemdes)) {
                // const image = document.getElementById('itemimage');
                // setImageChecking(true)
                // const model = await nsfwjs.load('https://nestheadsfrontend.esteplogic.com/models/mobilenet_v2/model.json');
                // let res = await model.classify(image);
                // setImageChecking(false)
                // let resdata = [];
                // res.map((item) => {
                //     if (item.probability > 0.50) {
                //         resdata.push(item.className);
                //     }
                // })
                // if (resdata.includes("Sexy") || resdata.includes('Hentai') || resdata.includes("porn")) {
                // let notSafe = ["Sexy", "Hentai", "Porn"];
                // if (resdata.filter((item) => notSafe.includes(item)).length > 0) {
                if (false) {
                    setModalError('Image you uploaded is not safe for work!');
                } else {

                    setCharacterItems(prev => [...prev, { title: itemdes, type: character_type, id: characterItems.length + 1 }])
                    setItemDes('')
                    onSelectCharacterImage(false)
                    // function saveImageToLocalStorage(image) {
                    //     return new Promise((resolve, reject) => {
                    //         const reader = new FileReader();
                    //         reader.onloadend = function (event) {
                    //             resolve(event.target.result);
                    //         };
                    //         reader.onerror = function (error) {
                    //             reject(error);
                    //         };
                    //         reader.readAsDataURL(image);
                    //     });
                    // }
                    // // Usage
                    // saveImageToLocalStorage(characterItemImage[0])
                    //     .then((image) => {
                    //         setCharacterItems(prev => {
                    //             let _data = [...prev, { title: itemdes, image: image, type: character_type, id: characterItems.length + 1 }]
                    //             localStorage.setItem('charactersItems', JSON.stringify(_data))
                    //             return _data
                    //         });
                    //         setModelOpen(false);
                    //         setItemDes('');
                    //     })
                    //     .catch((error) => {
                    //         console.error('Error reading image:', error);
                    //     });

                }
                onSelectCharacterImage(false);
            } else {
                setModalError('Only use words which are safe for work!');
            }
        } else {
            setModalError('Please Enter  Description!')
        }
    }

    const openModel = () => {
        setModelOpen(true)
    }

    const closeModel = () => {
        setModelOpen(false);
        setItemDes('');
        onSelectCharacterImage(false);
        setModalError(true);
        setModalError(false);
    }
    const closeTermsModel = () => {
        setTermsModel(false);
    }

    const removeItem = (id) => {
        let _characterItems = characterItems.filter((item) => item.id != id)
        // localStorage.setItem('charactersItems', JSON.stringify(_characterItems))
        setCharacterItems(_characterItems)
    }

    const aiplus = () => {
        navigate('/chat/' + quests[0].id, { state: { quests: quests } })
    }
    const learnMore = (val) => {
        if (val && val !== null) {
            window.open("https://" + val)
        } else {
            window.open('https://nestheadsfrontend.esteplogic.com')
        }
        // navigate('/chat/' + quests[0].id, { state: { quests: quests } })
    }

    const getTotalitems = () => {
        let helthylen = 0;
        let toxiclen = 0;

        characterItems.map((item) => {
            if (item.type == 'h') {
                helthylen++
            } else if (item.type == 't') {
                toxiclen++
            }
        })

        if (helthylen > toxiclen) return helthylen
        return toxiclen

    }

    const onCategorySelect = (item) => {
        setCharacterCategory(item)
    }

    useEffect(() => {
        setLoading(true);
        // let _characterItems = localStorage.getItem("charactersItems")
        // if (_characterItems != null) {
        //     setCharacterItems(JSON.parse(_characterItems))
        // }

        Api.post('chat-category/list')
            .then((res) => {
                if (res.success) {
                    let requiedIds = [];
                    quests.map((item) => requiedIds.push(item.character_category))
                    let _data = res.data.filter((item) => requiedIds.includes(item.id))
                    setCategories(_data)
                    scrollToTop()
                } else {
                }
            })
            .catch((err) => {
                scrollToTop();
            })
            .finally(() => {
                setLoading(false);
            })
        // return () => setCharacterCategory(false)
    }, [])

    const onValChange = (k, v) => {

        setAlert({ msg: "", varient: "" })

        let _stateValus = { ...stateVales }

        _stateValus[k] = v
        if (_stateValus[k].trim() == "") {
            delete _stateValus[k]
        }

        setStatesVals(_stateValus)
    }

    const onPublicChange = () => {
        setPublic(!isPublic)
    }
    const onTermsChange = () => {
        setTermsAcceptance(!termsAccepted)
        setTermsError(false)
    }

    const onDesTextChange = (event) => {
        setItemDes(event.target.value); setModalError(false)
    }

    const nestNameSubmit = (e) => {
        e.preventDefault()
        if (nestname.trim() == "") {
            setNestName("New N.E.S.T.");
        }
        setInputFieldActive(false)
    }

    const onTitleClick = () => {
        setInputFieldActive(true);
        setTimeout(() => {
            titleRef.current.focus();
        }, 100)
    }

    const onTitleBlur = () => {
        if (nestname.trim() == "") {
            setNestName("New N.E.S.T.");
        }
        setInputFieldActive(false);
    }

    const onTitleChange = (e) => {
        setNestName(e.target.value);
    }

    if (loading) {
        return (
            <div style={{ height: '100vh', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
                <Lottie
                    animationData={animationData}
                    loop={true}
                    autoplay={true}
                    style={{ width: 100, height: 100 }}
                />
            </div>
        )
    }

    return (
        <div className="main_outer_container">
            <div className="page_container" style={{ border: '0px solid lightgray', boxShadow: '0 0 10px lightgray', padding: "10px 20px", borderRadius: 20, marginBottom: '40px' }} >

                {/* <Modal
                    show={isModelOpen}
                    onBackdropClick={() => setModelOpen(false)}
                >
                    <form onSubmit={addCharacter} >
                        <Modal.Header>
                            <h4 style={{ fontFamily: 'Dimbo' }}>Add {character_type == 't' ? "Toxic" : "Healthy"} Item</h4>
                            <div onClick={closeModel} style={{ width: '25px', height: '25px', backgroundColor: "#000", display: 'flex', borderRadius: '50%', justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }} >
                                <img src={require('../assets/cross.png')} width={'50%'} height={'50%'} />
                            </div>
                        </Modal.Header>
                        <Modal.Body>
                            {modalError && <div className="border border-danger p-2 text-danger rounded mb-2" >{modalError}</div>}
                            <h5 style={{ fontFamily: 'Dimbo' }} >Select Image</h5>
                            <div className="add_character_item_image_container" >
                                <div className={`add_character_item_image`}>
                                    <img id="itemimage" src={characterItemImage[0] ? URL.createObjectURL(characterItemImage[0]) : require('../assets/gray-bg.png')} />
                                    <input type='file' ref={characterItemImageref} style={{ display: 'none' }} accept="image/*" onChange={(e) => { onSelectCharacterImage(e.target.files); setModalError(false) }} />
                                </div>
                                <div className="mx-2">
                                    <Buttons onClick={() => characterItemImageref.current.click()} title={"Select Image"} style={{ height: '30px', lineHeight: "25px" }} />
                                </div>
                            </div>
                            <div className="create_character_fields" >
                                <h5 style={{ textAlign: 'left', fontFamily: 'Dimbo' }}>Description</h5>
                                <input onChange={(event) => { setItemDes(event.target.value); setModalError(false) }} style={{ width: '100%' }} />
                            </div>
                        </Modal.Body>
                    </form>
                    <Modal.Footer>
                        <Buttons title={'Add'} style={{ height: '40px', lineHeight: '40px', margin: 0 }} loading={imageChecking} onClick={addCharacter} />
                    </Modal.Footer>
                </Modal> */}

                <Modal
                    show={isTermsOpen}
                    onBackdropClick={() => closeTermsModel(false)}
                >
                    <form onSubmit={addCharacter} >
                        <Modal.Header>
                            <h4 style={{ fontFamily: 'Dimbo' }}>Create NEST</h4>
                            <div onClick={closeTermsModel} style={{ width: '25px', height: '25px', }} className="d-flex rounded-start-pill rounded-end-pill justify-content-center align-items-center cursor-pointer bg-dark" >
                                <img src={require('../assets/cross.png')} width={'50%'} height={'50%'} />
                            </div>
                        </Modal.Header>
                        <Modal.Body>
                            {/* <h5 style={{ fontFamily: 'Dimbo' }} >Terms and conditions</h5> */}
                            {termsErr && <div className="border border-danger rounded px-2 py-1 mb-2 text-danger">Please accept the Terms and Conditions!</div>}
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <CheckBox value={isPublic} onChange={onPublicChange} /> <div>Set as Public</div>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <CheckBox value={termsAccepted} onChange={onTermsChange} /> <div>I accept the <a style={{ color: '#000' }} href="https://nestheads.com/term-of-use/" target='_blank'>Terms and Conditions</a>.</div>
                            </div>
                        </Modal.Body>
                    </form>
                    <Modal.Footer>
                        <Buttons title={'Create'} style={{ height: '40px', lineHeight: '40px', margin: 0 }} loading={savingCharacter} onClick={saveCharacter} />
                    </Modal.Footer>
                </Modal>
                <div className="create_character" >
                    {_alert.msg &&
                        <Alert variant={_alert.varient} dismissible onClose={() => setAlert({ varient: "", msg: "" })}>
                            {_alert.msg}
                        </Alert>
                    }
                    {/* <h2 style={{ width: 'fit-content', margin: '10px auto' }}>Create N.E.S.T.</h2> */}
                    {!isInputFieldActive ?
                        <h2 style={{ width: 'fit-content', margin: '20px auto', fontFamily: 'Dimbo' }} onClick={onTitleClick} >{nestname}</h2>
                        :
                        <form onSubmit={nestNameSubmit} >
                            <input ref={titleRef} onBlur={onTitleBlur} onChange={onTitleChange} value={nestname} style={{ width: '100%', fontSize: '25px', margin: '10px 0' }} />
                        </form>
                    }
                    <div className="my-3">
                        <div style={{ backgroundColor: '#eee', borderRadius: 5, marginBottom: 5, display: 'flex', alignItems: 'center' }}  >
                            <img src={require('.././assets/map_toxic.png')} style={{ width: 55, height: 55, objectFit: 'contain', margin: "0 10px" }} />
                            <input style={{ backgroundColor: 'transparent', flex: 1, outline: 0, border: '0px solid transparent' }} value={stateVales?.undesirable || ""} placeholder={"WHAT IS THE UNDESIRABE STATE OF THIS NEST?"} onChange={(e) => onValChange('undesirable', e.target.value)} />
                        </div>
                        <div style={{ backgroundColor: '#eee', borderRadius: 5, display: 'flex', alignItems: 'center' }}>
                            <img src={require('.././assets/map.png')} style={{ width: 55, height: 55, objectFit: 'contain', margin: "0 10px" }} />
                            <input style={{ backgroundColor: 'transparent', flex: 1, outline: 0, border: '0px solid transparent' }} value={stateVales?.desirable || ""} placeholder={"WHAT IS THE DESIRABE STATE OF THIS NEST?"} onChange={(e) => onValChange('desirable', e.target.value)} />
                        </div>
                        {/* <InputField dataKey="undesirable" value={stateVales?.undesirable || ""} placeholder={"WHAT IS THE UNDESIRABE STATE OF THIS NEST?"} onChange={onValChange} />
                        <InputField dataKey="desirable" value={stateVales?.desirable || ""} placeholder={"WHAT IS THE DESIRABE STATE OF THIS NEST?"} onChange={onValChange} /> */}
                    </div>
                    <div>

                        <div className='d-flex no-wrap overflow-scroll'>
                            {categories.map((item, i) => {
                                return (
                                    <div key={i} onClick={() => onCategorySelect(item)} style={{ padding: '10px 0px' }} className={`character_category_container ${characterCategory.name == item.name && 'active'}`}>
                                        <div className="image_container" >
                                            <img src={characterCategory.name == item.name ? item?.photo_healthy?.[0] : item.photo_toxic} width={"100%"} height={'100%'} style={{ objectFit: 'contain' }} />
                                        </div>
                                        <div className='text-center'>{item.name}</div>
                                        {(characterCategory.name == item.name && characterItems.length > 0) &&
                                            < div style={{ position: 'absolute', top: 5, right: 5, backgroundColor: '#000', borderRadius: '50%', width: 20, height: 20, fontSize: 10, color: "#fff", lineHeight: '20px', textAlign: 'center' }} >
                                                {getTotalitems()}
                                            </div>
                                        }
                                    </div>
                                )
                            })}
                        </div>
                        {characterCategory.name &&
                            <div className="d-flex my-2" style={{ borderTop: '2px solid #f23ff7' }}>
                                <div style={{ backgroundColor: '#f23ff7' }} className="character_description" >
                                    <div className="d-flex justify-content-center align-items-center flex-column">
                                        {characterCategory.name.split('').reverse().map((word) => (
                                            <div style={{ display: 'block', height: '14px', lineHeight: '14px', fontSize: 18, textTransform: "capitalize", transform: 'rotate(270deg)', color: '#fff' }} >
                                                {word}
                                            </div>
                                        ))}
                                    </div>
                                    <img src={characterCategory.image} alt="charact_image" />
                                </div>
                                <div className="d-flex flex-column justify-content-around flex-grow-1" style={{ fontSize: 18, margin: '0 10px' }} >
                                    <div>{characterCategory.description}</div>
                                    <div className="d-flex justify-content-center">
                                        <Buttons title={`Learn More About ${characterCategory.name}`} onClick={() => learnMore(characterCategory.category_url)} style={{ height: '35px', margin: '0', fontSize: '14px', backgroundColor: "#f23ff7", lineHeight: '35px', width: 'fit-content', textWrap: 'nowrap', marginRight: 10, borderWidth: 0, boxShadow: '0px 5px 10px lightgray' }} />
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                    <div className="second_container">
                        <div className='quest_list_container my-3'>
                            <div style={{ display: 'flex', flexWrap: 'nowrap', maxWidth: '100%' }} >
                                {quests?.map((item, i) => {

                                    if (characterCategory?.id && characterCategory.id != item.character_category) return
                                    return (
                                        <div key={i} >
                                            <div style={{ marginLeft: i == 0 ? 0 : '10px' }} className={`chat_component_character_listing active`}>
                                                {item.image ?
                                                    <img src={item.image} width={'80%'} height={"80%"} style={{ objectFit: 'contain' }} />
                                                    :
                                                    <div style={{ backgroundColor: "#ddd", borderRadius: '50%', width: '100%', height: '100%' }} ></div>
                                                }
                                            </div>
                                            {/* {quests.length > 1 && < div style={{ borderTop: '3px solid #000', width: '90px', zIndex: 1, position: 'absolute', top: '50%' }} ></div>} */}
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                        <div className="healthy_toxic_container my-5">
                            <div onClick={() => changecategorytype('t')} className={`healthy_toxic_buttons ${character_type == 't' && "active"}`}>Toxic</div>
                            <div onClick={() => changecategorytype('h')} className={`healthy_toxic_buttons ${character_type == 'h' && "active"}`}>Healthy</div>
                        </div>
                        {/* <div style={{ display: 'flex', justifyContent: 'flex-end', margin: "5px 0px" }}>
                            <input type="file" ref={imageref} style={{ display: 'none' }} accept="image/*" onChange={(e) => onSelectFiles(e.target.files)} />
                            <div onClick={selectFile} className={`upload_character_image`} >
                                <img src={characterImage[character_type] ? URL.createObjectURL(characterImage[character_type][0]) : require('../../src/assets/user.png')} />
                            </div>
                        </div> */}
                        <div className="character_items_container" >
                            <div className="create_nest_heading m-auto w-fit" >
                                Toxic
                            </div>
                            <div style={{ flex: 1, margin: "0px 10px", backgroundColor: character_type == 'h' ? '#eee' : '#fff', padding: '0 10px', borderRadius: 10, }} >
                                {characterItems.map((item, i) => {
                                    if (item.type == 'h') return
                                    return (
                                        <div key={i} style={{
                                            boxShadow: '0px 5px 10px 1px lightgray ', padding: '10px', borderRadius: '30px', display: 'flex', alignItems: 'center', margin: '10px 0',
                                        }}>
                                            <div style={{ display: 'flex', flex: 1, alignItems: 'center' }}>
                                                <div style={{ width: '30px', height: '30px', minWidth: '30px', minHeight: '30px' }} >
                                                    <img src={characterCategory.image} width={'100%'} height={'100%'} style={{ objectFit: 'contain' }} />
                                                </div>
                                                <div style={{ marginLeft: 10, wordBreak: 'break-all' }}>
                                                    {item.title}
                                                </div>
                                            </div>
                                            <div onClick={() => removeItem(item.id)} style={{ width: 20, height: 20, display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '50%', backgroundColor: '#000', cursor: 'pointer' }} >
                                                <img src={require('../assets/cross.png')} width={'40%'} height={'40%'} />
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                            <div className="create_nest_heading m-auto w-fit" >
                                Healthy
                            </div>
                            <div style={{ flex: 1, margin: "0px 10px", backgroundColor: character_type == 't' ? '#eee' : '#fff', padding: '0 10px', borderRadius: 10, }} >
                                {characterItems.map((item, i) => {
                                    if (item.type == 't') return

                                    return (
                                        <div key={i} style={{ boxShadow: '0px 5px 10px 1px lightgray ', padding: '10px', borderRadius: '30px', display: 'flex', alignItems: 'center', margin: '10px 0', }}>
                                            <div style={{ display: 'flex', flex: 1, alignItems: 'center' }}>
                                                <div style={{ width: '30px', height: '30px', minWidth: '30px', minHeight: '30px' }} >
                                                    <img src={characterCategory.image} width={'100%'} height={'100%'} style={{ objectFit: 'contain' }} />
                                                </div>
                                                <div style={{ marginLeft: 10, wordBreak: 'break-all' }}>
                                                    {item.title}
                                                </div>
                                            </div>
                                            <div onClick={() => removeItem(item.id)} style={{ width: 20, height: 20, display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '50%', backgroundColor: '#000', cursor: 'pointer' }} >
                                                <img src={require('../assets/cross.png')} width={'40%'} height={'40%'} />
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                        {/* <div style={{ display: 'flex', justifyContent: 'flex-end', margin: "5px 0px" }}>
                            <input type="file" ref={imageref} style={{ display: 'none' }} accept="image/*" onChange={(e) => onSelectFiles(e.target.files)} />
                            <div onClick={openModel} className="upload_character_image p-2" >
                                <img src={require('../../src/assets/plus-black.png')} />
                            </div>
                        </div> */}
                        {/* <div>
                            <div className="create_character_fields" >
                                <input value={staticFormData.headname || ""} onChange={(e) => onStaticFieldValueChange('headname', e.target.value)} type="text" placeholder={"Head Name"} />
                            </div>
                            <div className="create_character_fields" >
                                <input value={formData[character_type].name || ""} onChange={(e) => onFieldValueChange('name', e.target.value)} type="text" placeholder={`${character_type == 't' ? "Toxic" : 'Healthy'}` + " Name"} />
                            </div>
                            <div className="create_character_fields">
                                <input value={formData[character_type].title || ""} onChange={(e) => onFieldValueChange('title', e.target.value)} type="text" placeholder={`${character_type == 't' ? "Toxic" : 'Healthy'}` + " Title"} />
                            </div>
                            <div className="create_character_fields">
                                <input value={formData[character_type].des || ""} onChange={(e) => onFieldValueChange('des', e.target.value)} type="text" placeholder={`${character_type == 't' ? "Toxic" : 'Healthy'}` + " Description"} />
                            </div>
                            <div className="create_character_fields" >
                                <input value={formData[character_type].heading || ""} onChange={(e) => onFieldValueChange('heading', e.target.value)} type="text" placeholder={`${character_type == 't' ? "Toxic" : 'Healthy'}` + " Heading"} />
                            </div>
                        </div> */}
                    </div>
                    <div className="create_character_input_container" >
                        <div className="d-flex flex-grow-1 align-items-center"  >
                            {/* <div className="add_character_item_image" onClick={() => characterItemImageref?.current?.click()}>
                                <img id="itemimage" src={characterItemImage[0] ? URL.createObjectURL(characterItemImage[0]) : require('../assets/gray-bg.png')} />
                                <input type='file' ref={characterItemImageref} style={{ display: 'none' }} accept="image/*" onChange={(e) => { onSelectCharacterImage(e.target.files); setModalError(false) }} />
                            </div> */}
                            {/* <input placeholder={(character_type == 'h' ? "Healthy" : "Toxic") + " value"} type="text" /> */}
                            <form style={{ width: '100%', marginRight: 10 }} onSubmit={addCharacter} >
                                <input style={{ width: '100%' }} value={itemdes} placeholder={(character_type == 'h' ? ((characterCategory?.name ? characterCategory.name : "") + " Healthy") : ((characterCategory?.name ? characterCategory.name : "") + " Toxic")) + " value"} onChange={onDesTextChange} />
                            </form>
                        </div>
                        <div onClick={addCharacter} style={{ cursor: 'pointer', width: 'fit-content', marginRight: 5, backgroundColor: "#000", borderRadius: '20px', color: '#fff', padding: '5px 20px', boxShadow: '0px 5px 6px lightgray' }} >Add</div>
                        {/* <Buttons title={"Add"} loading={imageChecking} onClick={addCharacter} style={{ height: '35px', margin: '0', fontSize: '14px', lineHeight: '35px', width: 'fit-content', textWrap: 'nowrap' }} /> */}
                    </div>
                    {modalError && <div className="border border-danger p-2 text-danger rounded my-2" >{modalError}</div>}
                    <div className="create_character_bottom_container">
                        <div className="left"></div>
                        <div className="center pb-2">
                            <div onClick={openTermsModel} style={{ cursor: 'pointer', fontSize: 30, padding: '10px 40px', color: '#fff', textShadow: "0px 4px 0px rgba(0, 0, 0, 0.8)", border: '2px solid #000', backgroundColor: 'red', borderRadius: 80 }} >Create N.E.S.T.</div>
                            {/* <Buttons title={"Create NEST"} onClick={openTermsModel} style={{ height: '35px', margin: '0', fontSize: '14px', lineHeight: '35px', width: 'fit-content', textWrap: 'nowrap' }} /> */}
                        </div> {/**required for centering the div */}
                        <div className="right pb-2" >
                            <div onClick={aiplus} style={{ cursor: 'pointer', width: 'fit-content', marginRight: 5, backgroundColor: "#000", borderRadius: '20px', color: '#fff', padding: '5px 20px', boxShadow: '0px 5px 6px lightgray' }} >AI +</div>
                            <div onClick={openTermsModel} style={{ cursor: 'pointer', width: 'fit-content', marginRight: 5, backgroundColor: "#000", borderRadius: '20px', color: '#fff', padding: '5px 20px', boxShadow: '0px 5px 6px lightgray' }} >Save</div>
                            {/* <Buttons title={"AI +"} onClick={aiplus} style={{ height: '35px', margin: '0', fontSize: '14px', lineHeight: '35px', width: 'fit-content', textWrap: 'nowrap', marginRight: 10 }} />
                            <Buttons title={"Save"} onClick={openTermsModel} style={{ height: '35px', margin: '0', fontSize: '14px', lineHeight: '35px', width: 'fit-content', textWrap: 'nowrap' }} /> */}
                        </div>
                    </div>
                </div>
            </div >
            <Footer />
        </div >
    )
}

export default CreateCharacter