import React, { useState, useEffect } from 'react'
import { Spinner } from 'react-bootstrap'

const Buttons = ({ title, onClick, loading = false, variant = 'default', disabled, image = null, style = {} }) => {

    const [loading_, setLoading] = useState(false)

    useEffect(() => {
        setLoading(loading)
    }, [loading])

    if (variant == 'transparent') {
        return (
            // <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
            <div onClick={disabled ? () => { } : onClick} className='px-4 px-md-5 custom_buttons' style={{ cursor: disabled ? "initial" : 'pointer', color: disabled ? "gray" : "black", fontFamily: 'Dimbo', backgroundColor: 'white', borderRadius: "40px", margin: '10px 0', textAlign: 'center', fontSize: '16px', border: `2px solid ${disabled ? "gray" : "black"}`, ...style }} >
                {loading_ ? <Spinner size='sm' /> : <>{image != null ? <> <img src={image} style={{ maxHeight: '20px', marginRight: "10px" }} />{title} </> : title}</>}
            </div>
            // </div>
        )
    }
    return (
        // <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
        <div onClick={disabled ? () => { } : onClick} className='px-4 px-md-5 custom_buttons' style={{ cursor: disabled ? "initial" : 'pointer', color: disabled ? "gray" : "white", fontFamily: 'Dimbo', backgroundColor: 'black', borderRadius: "40px", margin: '10px 0', textAlign: 'center', fontSize: '16px', border: `2px solid ${disabled ? "gray" : "black"}`, ...style }} >
            {loading_ ? <Spinner size='sm' /> : <>{image != null ? <> <img src={image} style={{ maxHeight: '20px', marginRight: "10px" }} />{title} </> : title}</>}
        </div>
        // </div >
    )

}

export default Buttons