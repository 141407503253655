import React, { useEffect, useState, useRef } from 'react'
import { useNavigate, useLocation } from 'react-router-dom';
import ChatMessage from './ChatMessage';
import Api from '../services/Api';
import { Helmet } from 'react-helmet';
import WebcamCapture from './WebCam';
import CustomModel from './CustomModel';
import { useDispatch, useSelector } from 'react-redux';
import { setCategoriesdata, addTitle } from '../actions/categoriesActions';
import { toast } from 'react-toastify';
import PrecentageComponent from './PercentageComponent';
import Buttons from './Buttons';
import { setChatdata, updateChatdata } from '../actions/chatActions';
import { chatsLimitDecrement } from '../actions/userAction';

let defaultimg = require('../assets/user.png');

const ChatComponent = (props) => {

    const user = useSelector((state) => state?.user);
    const isLogged = useSelector((state) => state?.user.isLogged);
    const defaultCharacter = useSelector(state => state.characotors.default_charactor)
    const chatsData = useSelector(state => state.userchats.chats);
    const activeSubscription = useSelector(state => state.user.subscription);
    // const quests = useSelector(state => state.quests.selected);

    const { chat_id = 0, character_id = null, input = 'fixed', varient = 'default', quests = null } = props;
    const [showAbout, setShowAbout] = useState(false);
    const [notAvilable, setNotAvilable] = useState(false);
    const [modelNotAvilable, setModelNotAvilable] = useState(false);
    const [loading, setLoading] = useState(false);
    const [character, setCharacter] = useState(null);
    const [message, setMessage] = useState("");
    const [chatMessages, setChatMessages] = useState([]);
    const [showField, setShowField] = useState(true);
    const [sending, setSending] = useState(false);
    const [chatID, setChatID] = useState(0);
    const [openCamera, setOpenCamera] = useState(false);
    const [modelData, setModelData] = useState({ title: '', description: '', onClick: null, varient: "default" });
    const [showPopup, setShowPopup] = useState(false);
    const [tags, setTags] = useState({});
    const [creatingCategoryLoading, setCreatingCategoryLoading] = useState(false);
    const [fileToUpload, setFileToUpload] = useState(null);
    const [isCamera, setIsCamera] = useState(false);
    const [percentage, setPercentage] = useState("0%");
    const [questCreating, setQuestCreating] = useState(false);
    const [changedChatID, setChangedChatID] = useState(false)
    const [currentlySelected, setCurrentlySelected] = useState(false)

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const location = useLocation();
    let { redirectmessage = null } = location?.state || { redirectmessage: null };

    const inputref = useRef();
    const activepopup = false;

    const openINput = () => {
        inputref.current.click()
    }

    const handleQuests = (res) => {
        if (!res) {
            setShowPopup(false)
            setModelData({ title: "", description: "", onClick: null, varient: '' })
            if (tags.tag_name.length) {
                let _tags = { ...tags };
                _tags.tag_name.shift();
                setTags(_tags);
            }
        } else {
            setCreatingCategoryLoading(true)
            Api.post('chat/category',
                {
                    "chat_id": JSON.stringify(chatID),
                    "tag_id": JSON.stringify(tags.tag_name[0].id),
                    "tag": tags.tag_name[0].name,
                    "chat_title": tags.chat_heading
                }
            )
                .then((res) => {
                    if (res.success) {
                        dispatch(setCategoriesdata(res.data.categories))
                        dispatch(addTitle(res.data?.chat_title))
                    }
                })
                .finally(() => {
                    setShowPopup(false)
                    setCreatingCategoryLoading(false)
                    setModelData({ title: "", description: "", onClick: null, varient: '' })
                    if (tags.tag_name.length) {
                        let _tags = { ...tags };
                        _tags.tag_name.shift();
                        setTags(_tags);
                    }
                })
        }
        setTags({})
    }

    useEffect(() => {
        if (!activepopup) {
            if (Object.keys(tags).length > 0) {
                let APIsArray = []

                tags?.tag_name?.map((e) => {
                    APIsArray.push(
                        Api.post('chat/category', {
                            "chat_id": JSON.stringify(chatID),
                            "tag_id": JSON.stringify(e.id),
                            "tag": e.name,
                            "chat_title": tags.chat_heading
                        }))
                })
                console.log(APIsArray)
                Promise.all(APIsArray)
                    .then((res) => {
                        // console.log(res)
                        dispatch(setCategoriesdata(res[res.length - 1].data.categories))
                    })
                    .finally(() => {
                        setTags({})
                    })
            }
        } else {

            setTimeout(() => {
                checkAnother()
            }, 1000)
        }
    }, [tags])


    const checkAnother = () => {
        // console.log(tags[1])
        if (tags?.tag_name?.[0]) {
            setModelData({
                title: character.name, image: character.image, color: character.color, description: "We've noticed you're talking a lot about " + tags.chat_heading + ". Would you like to categorize this as a '" + tags.tag_name[0].name + "'?", onClick: handleQuests, varient: 'quest'
            })
            setShowPopup(true);
        }
    }

    const subscriptionExpired = (res) => {
        if (res) {
            navigate('/subscription', { state: { from: location.pathname, redirectmessage: message } })
            setModelData({ title: '', description: '', onClick: null, varient: "" })
            setShowPopup(false)
        } else {
            setModelData({ title: '', description: '', onClick: null, varient: "" })
            setShowPopup(false)
        }
    }

    const send = (e) => {
        e.preventDefault();
        if (message.trim() == "" && fileToUpload == null) {
            return
        }
        if (sending) {
            return
        }
        if (!isLogged) {
            navigate('/login', { state: { from: "homepage", redirectmessage: message } });
            return
        }
        if (activeSubscription == null) {
            navigate('/subscription', { state: { from: varient == 'homepage' ? '/' : input == "static" ? "/chat/category/nestheads" : "/chat", redirectmessage: message } })
            return
        }
        if ((!activeSubscription.chats_limit || activeSubscription.chats_limit == "" || activeSubscription.chats_limit == 0) && chatID == 0) {
            setModelData({
                title: "Chats limit Reached!", description: "Chats limit Reached! Please subscribe to use nestheads!", onClick: subscriptionExpired
            })
            setShowPopup(true)
            return
        }

        let obj = {
            dataTime: new Date().getTime(),
            message: message,
            by: 'U',
            files: fileToUpload
        };

        let Chatobj = {
            dataTime: new Date().getTime(),
            character: currentlySelected,
            message:
                "Typing...",
            by: 'C'
        };

        setChatMessages(prev => [...prev, obj, Chatobj]);

        setSending(true)
        let data = new FormData()
        data.append("chat_id", chatID)
        data.append("character_id", isLogged ? (changedChatID ? changedChatID : character_id) : defaultCharacter.id)
        data.append("message", message)
        if (typeof fileToUpload == 'string' && fileToUpload?.includes("data:image")) {
            data.append("file", fileToUpload)
        } else if (fileToUpload) {
            data.append("file", fileToUpload[0])
        }
        if (isCamera) {
            data.append("camera", true)
        }

        let headers = {
            "content-type": fileToUpload ? "multipart/form-data" : 'application/json',
            'Authorization': "Bearer " + localStorage.getItem('nestheadToken'),
            'Accept': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Max-Age': 600,
        }

        // setMessage('')
        // setSelectedFiles(null)
        // setSelectedImages(null)

        // return
        let loaded = true
        Api.post('chat/sendMessage', data, { headers })
            .then((resData) => {
                // console.log(resData);
                let showModel = false
                if (resData.success) {
                    if (resData.data.list.allow_verify) {
                        setModelNotAvilable(false);
                    } else {
                        setModelNotAvilable(true);
                    }
                    if (resData.data.list?.percentage) {
                        setPercentage(resData.data.list.percentage + "%");
                    }
                    let res = resData.data.list.message;
                    let msg = res.split(' ');
                    let _text = '';
                    let end = msg.length;
                    let reached = 0;
                    // setChatId(resData.data.list.chat_id)
                    if (chatID == 0) {
                        dispatch(chatsLimitDecrement())
                    }
                    setChatID(resData.data.list.chat_id)
                    dispatch(updateChatdata(resData.data.list));
                    if (input == 'static' && varient != 'homepage') {
                        navigate('/chat/' + character_id + "/" + resData.data.list.chat_id)
                    }
                    loaded = false
                    let interval = setInterval(() => {
                        if (reached == end) {
                            // console.log(tags)
                            if (resData.data.list?.tags) {
                                setTags(resData.data.list?.tags)
                            }
                            clearInterval(interval)
                            loaded = true
                            setSending(false)
                        } else {
                            _text = _text + msg[reached] + " ";
                            reached++
                            let obj = {
                                dataTime: new Date().getTime(),
                                message: _text,
                                character: resData.data.list.character,
                                // image: ['https://api.nestheads.app/assets/images/1700570680Conscience.png', 'https://api.nestheads.app/assets/images/o2wrShock.PNG'],
                                // files: ['https://docs.google.com/document/d/1WPa1cdLrl9kSeYy-24q2FDOA0EhJn0KCqmIImaPnJyE/edit', 'https://www.nestheads.app/static/media/nestheads.7e22ca9de167beff1293.mp4'],
                                by: "C"
                            }
                            setChatMessages(prev => {
                                let _prev = prev
                                _prev[prev.length - 1] = obj
                                return [..._prev]
                            })
                        }
                    }, 10)
                } else {
                    // if (resData.message = 'The audio file could not be decoded or its format is not supported') {
                    //     toast.error(resData.message);
                    // }

                    // toast.error(resData.message);
                    // if (input == 'static') {
                    //     navigate('/chat')
                    // }

                    let obj = {
                        dataTime: new Date().getTime(),
                        message: resData.message || "Some error occurred!",
                        by: "C"
                    }

                    setChatMessages(prev => {
                        let _prev = prev
                        _prev[prev.length - 1] = obj
                        return [..._prev]
                    })
                }
            })
            .catch((err) => {
                console.log(err)

                let obj = {
                    dataTime: new Date().getTime(),
                    message: "Some error occurred Please try again!",
                    by: "C"
                }

                setChatMessages(prev => {
                    let _prev = prev
                    _prev[prev.length - 1] = obj
                    return [..._prev]
                })
            })
            .finally(() => {
                if (loaded) {
                    setSending(false)
                }
            })
        setMessage('')
        setFileToUpload(null)
        setIsCamera(false)
    }


    useEffect(() => {
        if (redirectmessage != null && varient == 'homepage') {
            setCharacter(defaultCharacter);
            setCurrentlySelected(defaultCharacter)
            return
        }

        if (character_id == null || character_id == 0) {
            setShowField(false);
            setLoading(true);
            setTimeout(() => {
                setLoading(false);
            }, 1000)
        } else {
            if (character_id == "default") {
                setCharacter(defaultCharacter)
                setCurrentlySelected(defaultCharacter)

            } else {
                setChatID(chat_id)
                setChatMessages([])
                setLoading(true)
                Api.post(`characters/detail/${parseInt(character_id)}`)
                    .then((res) => {
                        if (res.success) {
                            setCharacter(res.data);
                            setCurrentlySelected(res.data)

                            if (res.data.status == null) {
                                setModelNotAvilable(true);
                                setShowField(false)
                            } else {
                                setModelNotAvilable(false);
                            }
                        } else {
                            setCharacter(null);
                            setCurrentlySelected(null)
                            setModelNotAvilable(true);
                            setShowField(false)
                        }
                    })
                    .catch(() => {
                        setCharacter(null)
                        setCurrentlySelected(null)
                        setModelNotAvilable(true);
                        setShowField(false)
                    }).finally(() => {
                        if (!chat_id) {
                            setLoading(false)
                        }
                    })
            }
            if (chat_id && redirectmessage == null) {
                setLoading(true)
                Api.post(`chat/detail`,
                    {
                        "chat_id": chat_id,
                        "character_id": JSON.stringify(parseInt(character_id))
                    })
                    .then((res) => {
                        if (res.success && res.data != null) {
                            setPercentage(res?.data?.[res.data.length - 1]?.percentage);
                            console.log(res.data)
                            setChatMessages(res.data);
                            setNotAvilable(false);
                            setShowField(true)

                        } else {
                            setNotAvilable(true);
                            setChatMessages([]);
                            setShowField(false);
                        }
                    })
                    .catch(() => {
                        setChatMessages([]);
                        setShowField(false);

                    }).finally(() => {
                        setLoading(false);
                    })
            } else {
                setShowField(true)
            }
        }
    }, [chat_id, character_id, defaultCharacter])

    useEffect(() => {
        if (redirectmessage != null) {
            setCharacter(defaultCharacter)
            setCurrentlySelected(defaultCharacter)
            setMessage(redirectmessage)
        }
    }, [location])

    const removeFile = (e) => {
        setIsCamera(false)
        setFileToUpload(null)
    }

    function getExtension(filename) {
        var parts = filename.split('.');
        return parts[parts.length - 1];
    }

    function isImage(filename) {
        var ext = getExtension(filename);
        switch (ext.toLowerCase()) {
            case 'jpg':
            case 'gif':
            case 'bmp':
            case 'png':
            case 'jpeg':
                //etc
                return true;
        }
        return false;
    }

    function isVideo(filename) {
        var ext = getExtension(filename);
        switch (ext.toLowerCase()) {
            case 'm4v':
            case 'avi':
            case 'mpg':
            case 'mp4':
                // etc
                return true;
        }
        return false;
    }

    const onSelectFiles = (e) => {

        let formats = ['mp3', 'mp4', 'mpeg', 'mpga', 'm4a', 'wav', 'webm']

        if (!isImage(Array.from(e)[0].name)) {
            if (Array.from(e)[0].size / 1024 > 24000) {
                toast.error('File size too big!')
                return
            } else if (!formats.includes(Array.from(e)[0].type.split("/")[1])) {
                toast.error('File type not supported!')
                return
            }
            else {
                setFileToUpload(e)
                return
            }
        }
        setFileToUpload(e)
    }

    useEffect(() => {
        if (!loading) {

            if (varient == 'homepage') {
                let height = document.getElementById("homepage_messages_containers").scrollHeight
                document.getElementById("homepage_messages_containers").scrollTo({ top: height, behavior: 'smooth' });

            } else {
                let _height = document.querySelector('.start_chat')?.offsetHeight || 0;
                if (input == "static") {
                    document.getElementById('mynestheads_chatComponent').scrollTo(0, _height)
                } else {
                    window.scrollTo(0, _height);
                }
            }
        }
    }, [chatMessages, fileToUpload])

    const onCloseCamera = () => {
        setOpenCamera(false)
        document.body.style.overflow = 'initial'
    }

    const handleBattle = () => {
        setQuestCreating(true)
        Api.post("chat/set-quest", {
            "chat_id": parseInt(chatID),
            "character_id": parseInt(character_id)
        })
            .then((res) => {
                if (res.success) {
                    let _chats = [];
                    chatsData.map((e) => {
                        if (e.chat_messages == null || (e.character_id == character_id && e.chat_id == chatID)) {
                            console.log(e);
                        } else {
                            _chats.push(e);
                        }
                    })
                    dispatch(setChatdata(_chats))
                    setTimeout(() => {
                        navigate('/chat/category/quests')
                    }, 10)
                    toast.success("Quest created!")
                } else {
                    toast.error(res.message)
                }
            })
            .catch(() => {
                toast.error("Error occurred!")
            })
            .finally(() => {
                setQuestCreating(false)
            })
    }

    const onCapture = (e) => {
        setIsCamera(true)
        setFileToUpload(e)
    }

    const handleOpenCamera = () => {
        setOpenCamera(true)
        document.body.style.overflow = 'hidden'
    }

    const selectCharacter = (_character) => {
        setCharacter(_character)
        setChangedChatID(_character.id)
        setCurrentlySelected(_character)
    }


    if (loading) {
        return (
            <div style={{ margin: '20px', width: 'auto' }} >
                <div style={{ backgroundColor: 'rgb(250, 250, 250)', borderRadius: '20px', height: '200px', width: '100%', margin: '5px 0px' }} ></div>
                <div style={{ backgroundColor: 'rgb(250, 250, 250)', borderRadius: '20px', height: '300px', width: '100%', margin: '5px 0px' }} ></div>
            </div>
        )
    }

    return (
        <div style={{ height: '100%', position: "relative", overflow: varient == 'homepage' ? "hidden" : 'initial' }}>
            <WebcamCapture onClose={onCloseCamera} openCamera={openCamera} onCapture={onCapture} />
            <CustomModel loading={creatingCategoryLoading} showPopup={showPopup} setShowPopup={setShowPopup} title={modelData.title} description={modelData.description} onConfirm={modelData.onClick} image={modelData?.image || null} varient={modelData.varient} color={modelData.color} />

            {varient != 'homepage' ?
                <div className='start_chat'>
                    <Helmet>
                        <title>{character?.seo || ""}</title>
                        <meta property='description' content={character?.description || ""} />
                    </Helmet>
                    <div className={`${input == 'static' ? "main_character_container_static" : "main_character_container"}`} >
                        {input != 'static' &&
                            <div className='quest_list_container'>
                                <div style={{ display: 'flex', flexWrap: 'nowrap', maxWidth: '100%' }} >
                                    {(quests?.length > 1) && quests?.map((item, i) => (
                                        <div>
                                            <div onClick={() => selectCharacter(item)} style={{ marginLeft: i == 0 ? 0 : '10px' }} className={`chat_component_character_listing ${currentlySelected.id == item.id && "active"}`}>
                                                {item.image ?
                                                    <img src={item.image} width={'80%'} height={"80%"} style={{ objectFit: 'contain' }} />
                                                    :
                                                    <div style={{ backgroundColor: "#ddd", borderRadius: '50%', width: '100%', height: '100%' }} ></div>
                                                }
                                            </div>
                                            {quests.length > 1 && <div style={{ borderTop: '3px solid #000', width: '90px', zIndex: 1, position: 'absolute', top: '50%' }} ></div>}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        }
                        <div className='chat_img_container'>
                            {character ?
                                <>
                                    <div className={`${showAbout ? 'about_character_active' : "about_character"}`}> Click on Character to learn more.</div>
                                    <a href={`/models/${character?.seo}`} onClick={e => e.preventDefault()}>
                                        <img src={character?.image || require('../../src/assets/gray-bg.png')} alt={character?.name} className={`${(chatMessages == null || chatMessages?.length == 0) ? 'chat_character_image' : "chat_character_image_small"}`} onMouseEnter={() => setShowAbout(true)} onMouseLeave={() => setShowAbout(false)} onClick={() => navigate(`/models/${character?.seo}`)} />
                                    </a>
                                </>
                                :
                                <div style={{}} className='model_not_available' ></div>
                            }
                        </div>
                        <div className={`${chatMessages?.length > 0 ? "starting_box_small" : 'starting_box'}`}>
                            <div style={{ color: character?.color || 'black' }}>{character?.name || "Character"}:</div>
                            <div>Hey there! What’s on your mind?</div>
                            <PrecentageComponent percent={parseInt(percentage) + '%'} />
                        </div>
                    </div>
                    <div className={`${input == 'static' ? "chat_messages_container_static" : 'chat_messages_container'}`} style={{ marginBottom: parseInt(percentage) > 75 ? '130px' : '100px', marginTop: "100px" }}>
                        {
                            (!modelNotAvilable && notAvilable) ?
                                <div style={{ fontFamily: 'Dimbo', fontSize: '20px', marginTop: '150px', textAlign: 'center' }}>Chat not Available</div>
                                :
                                chatMessages && chatMessages.map((e, i) => (
                                    <div key={i} style={{ display: 'flex', flexDirection: 'column' }}>
                                        <ChatMessage msgdata={e} character={character} varient={varient} />
                                    </div>
                                ))
                        }
                    </div>
                </div>
                :
                <>
                    <div className='' style={{ position: 'absolute', top: 0, width: '100%', padding: '20px', zIndex: 10, backgroundColor: "white", }}>
                        <PrecentageComponent percent={isLogged ? percentage : "0"} height="20" color='rgba(255, 87, 66, 1)' showPercentage={false} title={percentage > 75 ? "" : "Needs More Information"} />
                    </div>
                    <div className='d-flex flex-column h-100 px-2 px-md-5 home_messages_container ' id={'homepage_messages_containers'} >
                        <div className='d-flex align-self-start align-items-end mb-3 msg_container_static_message'>
                            <div style={{ width: '70px', minWidth: '70px', height: '70px', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: "10px", border: '2px solid black', borderRadius: '50%' }} >
                                <img src={character?.image || require('../../src/assets/gray-bg.png')} style={{ maxWidth: '90%' }} />
                            </div>
                            <div className='message_container_landing_left' style={{ marginLeft: "60px", padding: '12px', backgroundColor: 'rgba(37, 33, 52, 1)', color: 'white', borderRadius: '10px' }} >
                                <div style={{ fontSize: '20px' }} ><div className='text-start'>{character?.name || "model"}:</div>
                                    Hey there, what’s on your mind?</div>
                            </div>
                        </div>
                        {notAvilable ?
                            <div style={{ fontFamily: 'Dimbo', fontSize: '20px', marginTop: '150px', textAlign: 'center' }}>Chat not Available</div>
                            : (chatMessages.length > 0)
                            && chatMessages.map((e, i) => (
                                <div key={i} className='d-flex flex-column'>
                                    <ChatMessage msgdata={e} character={character} varient={"homepage"} />
                                </div>
                            ))
                        }
                    </div>
                </>
            }

            {modelNotAvilable && <div style={{ fontFamily: 'Dimbo', fontSize: '22px', textAlign: 'center', marginBottom: '50px' }} >Model not Available!</div>}

            {/** input field start **/}
            {
                (showField || varient == 'homepage') &&
                <form onSubmit={sending ? (e) => { e.preventDefault() } : send} className={`chat_field ${input == "static" && " chat_field_static"}`} style={{ flexDirection: 'column' }}>
                    <div style={{ flex: 1, display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end' }}>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
                            <div className='add_btn' onClick={openINput}  >
                                <img src={require("../assets/plus.png")} width={16} />
                            </div>
                            <div className='add_btn_text'>Add</div>
                        </div>
                        <input type='file' ref={inputref} style={{ display: 'none' }} onChange={(e) => onSelectFiles(e.target.files)} />
                        <div style={{ margin: "0 10px", overflow: 'hidden', flex: 1, position: 'relative', borderRadius: "25px", border: (fileToUpload == null) ? 'none' : '1px solid rgba(30, 30, 30, 0.4)', borderBottom: fileToUpload != null ? "0px" : "1px" }} >
                            {(fileToUpload != null) &&
                                <div style={{ display: 'flex', background: '#fff', overflow: 'scroll' }}>
                                    {Array.from(fileToUpload).map((e, index) => {
                                        if (isImage(e.name)) {
                                            return (
                                                <div key={index} style={{ position: 'sticky', bottom: 47, padding: 10, }}>
                                                    <div style={{ width: 'fit-content', position: 'relative', padding: 10, backgroundColor: 'rgba(232, 236, 239, 0.4)', borderRadius: 10, overflow: 'hidden' }} >
                                                        <div onClick={() => removeFile(index)} style={{ position: 'absolute', right: 4, top: 4, width: 20, height: 20, fontSize: 12, textAlign: 'center', cursor: 'pointer', backgroundColor: 'rgba(0,0,0, 0.5)', borderRadius: '50%' }}>
                                                            <img src={require('../assets/cross.png')} style={{ width: 8, height: 8 }} />
                                                        </div>
                                                        {
                                                            e.name ?
                                                                <img alt="preview image" className='input_field_selected_image' src={URL.createObjectURL(e)} />
                                                                :
                                                                <img alt="preview image" className='input_field_selected_image' src={e} />
                                                        }
                                                    </div>
                                                </div>
                                            )
                                        } else if (isVideo(e.name)) {
                                            return (
                                                <div key={index} style={{ position: 'sticky', bottom: 47, padding: 10, }}>
                                                    <div style={{ width: 'fit-content', position: 'relative', padding: 10, backgroundColor: 'rgba(232, 236, 239, 0.4)', borderRadius: 10, overflow: 'hidden' }} >
                                                        <div onClick={() => removeFile(index)} style={{ position: 'absolute', right: 4, top: 4, width: 20, height: 20, fontSize: 12, textAlign: 'center', cursor: 'pointer', backgroundColor: 'rgba(0,0,0, 0.5)', borderRadius: '50%' }}>
                                                            <img src={require('../assets/cross.png')} style={{ width: 8, height: 8 }} />
                                                        </div>
                                                        {
                                                            e.name ?
                                                                <video className='input_field_selected_video'>
                                                                    <source alt="preview image" src={URL.createObjectURL(e)} />
                                                                </video>
                                                                :
                                                                <video className='input_field_selected_video' >
                                                                    <source alt="preview image" style={{ width: '130px', height: '130px', objectFit: 'cover' }} src={e} />
                                                                </video>
                                                        }
                                                    </div>
                                                </div>
                                            )
                                        } else {
                                            return (
                                                <div key={index} style={{ position: 'sticky', bottom: 47, padding: 10, }}>
                                                    <div style={{ width: 'fit-content', position: 'relative', padding: 10, backgroundColor: 'rgba(232, 236, 239, 0.4)', borderRadius: 10, overflow: 'hidden' }} >
                                                        <div onClick={() => removeFile(index)} style={{ position: 'absolute', right: 4, top: 4, width: 20, height: 20, fontSize: 12, textAlign: 'center', cursor: 'pointer', backgroundColor: 'rgba(0 , 0, 0, 0.5)', borderRadius: '50%' }}>
                                                            <img src={require('../assets/cross.png')} style={{ width: 8, height: 8 }} />
                                                        </div>
                                                        {/* <img alt="preview image" style={{ width: '130px', height: '130px' }} src={URL.createObjectURL(e)} /> */}
                                                        <div className='input_field_selected_file' >
                                                            <div style={{ height: '60px' }}  >
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" fill="currentColor" className="bi bi-file-earmark" viewBox="0 0 16 16">
                                                                    <path d="M14 4.5V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h5.5zm-3 0A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V4.5z" />
                                                                </svg>
                                                            </div>
                                                            {e.name}
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    })}
                                </div>
                            }
                            <input placeholder='Message' value={message} onChange={(e) => setMessage(e.target.value)} />
                        </div>
                        <div className='chat_btn' onClick={send} >
                            {varient == "homepage" ? <img src={require('../assets/send.png')} alt='Send button' style={{ maxWidth: '20px', objectFit: 'contain' }} /> : <img src={require('../assets/arrow_up.png')} alt='Send button' />}
                        </div>
                    </div>
                    {/** input field end **/}

                    {/** start battle button **/}
                    {(input != 'static' || varient == 'homepage') &&
                        <div style={{ textAlign: 'center', width: 'fit-content', margin: 'auto', display: 'flex' }}>
                            <Buttons disabled={parseInt(percentage) <= 75} loading={questCreating} variant='transparent' title={"Start Battle"} onClick={handleBattle} />
                        </div>}
                </form>
            }
        </div >
    )
}

export default ChatComponent