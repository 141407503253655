import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Api from "../services/Api";
import { useNavigate } from "react-router-dom";
import CharacterDetails from "./CharacterDetails";
import Footer from "../components/footer";

const ModalDetail = () => {

    const { seo } = useParams();

    const [charector, setCharacter] = useState({});
    const [history, setHistory] = useState([]);
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        if (seo) {
            let url = `characters/public/detail/${seo}`
            Api.post(url)
                .then((res) => {
                    if (res.success) {
                        setCharacter(res.data)
                        if (res.data.chat_messages) {
                            const sortedArray = res.data.chat_messages.sort((a, b) => a.msg_id - b.msg_id);
                            setHistory(sortedArray);
                        }
                    } else {
                        setCharacter({})
                    }
                })
                .catch(() => {
                    navigate('/models')
                })
                .finally(() => {
                    setLoading(false);
                })
        }
    }, [seo])

    return (
        <div className="page_container" >
            <CharacterDetails Character={charector} loading={loading} history={history} />
        </div>
    )
}

export default ModalDetail