import React from "react";
import Buttons from "./Buttons";
import Icon from "react-icons-kit";
import { ic_public_outline } from 'react-icons-kit/md/ic_public_outline'
import { ic_public_twotone } from 'react-icons-kit/md/ic_public_twotone'
import { trash2 } from 'react-icons-kit/feather/trash2';
import { Spinner } from "react-bootstrap";

const NestListing = (props) => {

    const { e, i, varient = 'public', deleteids, handlePublic, deleteNest } = props;

    const handleguid = (e, data) => {
        e.stopPropagation();
        window.open(`https://www.nestheads.app/guid?id=${data}`, "_blank")
    }

    const _handlePublic = (e, first, sec) => {
        e.stopPropagation();
        handlePublic(first, sec)
    }

    const _deleteQuest = (e, chatid, characterid, index) => {
        e.stopPropagation();
        deleteNest(chatid, characterid, index)
    }
    return (
        <div onClick={(eve) => handleguid(eve, e?.guid || 0)} className=' my-4 my-lg-5 px-3 px-lg-4 px-md-3 py-4 py-lg-5 border border-dark border-4' style={{ borderRadius: '30px', overflow: "hidden", boxShadow: "0px 5px 15px 2px #eee", cursor: 'pointer' }} >
            <div className="d-flex justify-content-between">
                <div className="d-flex flex-grow-1 flex-wrap"  >
                    {e.character.map((item) => (
                        <div className="d-flex align-items-center mb-2 mr-4">
                            <div className='mx-1 mx-sm-2'>
                                <img referrerPolicy="no-referrer" src={item.photo || require("../assets/user.png")} className='quest_header_user_img' />
                            </div>
                            {/* <div>
                                                        <div className="quest_slider_model_seo lh-1 " style={{ fontFamily: 'Dimbo', overflow: 'hidden' }} >{'test'}</div>
                                                    </div> */}
                        </div>
                    ))
                    }
                </div>
                <div>
                    <img referrerPolicy="no-referrer" src={e?.category.image || require("../assets/user.png")} className='quest_header_user_img' />
                </div>
            </div>
            <div className="my-2">
                <div className="d-flex">
                    <div style={{ fontWeight: 600 }} >Desired State:</div>
                    <div>{e.desired_state}</div>
                </div>
                <div className="d-flex">
                    <div style={{ fontWeight: 600 }} >Undesired State:</div>
                    <div>{e.undesired_state}</div>
                </div>
            </div>
            <div className="" style={{ marginTop: '20px' }}>
                {Object.values(e?.healthy_item) != 0 &&
                    <>
                        <div className="my-2" style={{ fontWeight: 600 }}>Healthy items</div>
                        {Object.values(e?.healthy_item).map((item) => {
                            return (
                                <div className="d-flex mb-2 align-items-center" >
                                    <div className="mx-2">
                                        <img src={e.image} style={{ width: 30, height: 30 }} />
                                    </div>
                                    <div>
                                        {item.title}
                                    </div>
                                </div>
                            )
                        })}
                    </>}
                {Object.values(e?.toxic_item) != 0 &&
                    <>
                        <div className="my-2" style={{ fontWeight: 600 }}>Toxic items</div>
                        {Object.values(e?.toxic_item).map((item) => (
                            <div className="d-flex mb-2 align-items-center" >
                                <div className="mx-2">
                                    <img src={e.image} style={{ width: 30, height: 30 }} />
                                </div>
                                <div>
                                    {item.title}
                                </div>
                            </div>
                        ))}
                    </>
                }
                <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', marginTop: '10px', alignItems: 'center' }}>
                    {varient == "public" &&
                        <div style={{ width: 'fit-content', margin: 'auto', marginTop: '20px' }}>
                            <Buttons title={"Play Now"} onClick={(eve) => handleguid(eve, e.guid)} />
                        </div>
                    }
                    {(varient != 'public') &&
                        <>
                            {
                            // isInputFieldActive ?
                            //     <div style={{ width: 'fit-content', margin: 'auto', marginTop: '20px', display: 'flex' }}>
                            //         <div style={{ marginRight: '10px' }} >
                            //             <Buttons title={"cancle"} onClick={handleCancleSummary} variant="transparent" />
                            //         </div>
                            //         <Buttons title={"Save"} onClick={(ele) => handleUpdateSummary(ele, e.chat_id, e.character.id, e.summary)} />
                            //     </div>
                            //     :
                                <>
                                    <div style={{ width: '70px' }}>
                                    </div>
                                    <div style={{ width: 'fit-content', margin: 'auto' }}>
                                        <Buttons title={"Play Now"} onClick={(eve) => handleguid(eve, e.guid)} />
                                    </div>
                                    <div style={{ display: 'flex' }}>
                                        <div title={e.visible ? "make chat private" : "make chat public"} onClick={(eve) => _handlePublic(eve, e.chat_id, e.visible == 0 ? true : false)} style={{ cursor: 'pointer', marginLeft: '10px' }} >
                                            <Icon icon={e.visible == 1 ? ic_public_twotone : ic_public_outline} className="quest_icons" />
                                        </div>
                                        <div onClick={(ele) => _deleteQuest(ele, e.chat_id, e.character.id, i)} style={{ cursor: 'pointer', marginLeft: '10px' }} >
                                            {deleteids == i ? <Spinner size="sm" /> : < Icon icon={trash2} className="quest_icons" />}
                                        </div>
                                    </div>
                                </>
                            }
                        </>
                    }
                </div>
            </div>
        </div >
    )
}

export default NestListing