import { createStore, combineReducers } from 'redux';
import UserReducer from './reducers/userReducer';
import categoryReducer from './reducers/categoryReducer';
import chatsReducer from './reducers/chatsReducer';
import charactorReducer from './reducers/charactorReducer';
import questReducer from './reducers/questReducer';

const rootReducer = combineReducers({
  user: UserReducer,
  category: categoryReducer,
  userchats: chatsReducer,
  characotors: charactorReducer,
  quests: questReducer,
});
const store = createStore(rootReducer);

export default store;